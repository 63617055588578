import React from 'react';
import {makeStyles} from '@material-ui/core/styles'
import Copyright from './Copyright'
import Logo from '../../assets/svg/logo_white.svg'
import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#292929",
        padding: "1rem",
        color: "#fff",

    }, logo: {
        width: 300,
        margin: "1rem auto"
    },
    divider: {
        backgroundColor: "#fff"
    },
    title: {
        marginLeft: '2rem'
    },
    list: {
        listStyleType: 'none',
        paddingInlineStart: '1.5rem',
    }
}))

function Footer() {
    const classes = useStyles();
    return (
        <Grid conainer className={classes.root}>
            <Grid item xs={12}>
                <Box className={classes.logo}>
                    <Logo/>
                </Box>

            </Grid>
            <Divider className={classes.divider}/>
            <Grid item container xs={12}>
                <Grid item xs={12} sm={4}>
                    <Box p={3}>

                        <Typography variant="body2" color="inherit">
                            Since 2011 we provide our clients with the best business advice from point of view of
                            software technology market. With solid
                            knowledge from business area combined with expertise from modern software landscape, we can
                            pay attention to aspects
                            unseen by others.
                        </Typography>
                        <Typography variant="body2" color="inherit">
                            We are not "cheap", and we are not recommend "cheap" solutions to our customers only from
                            the price point of view. However, we
                            are affordable and highly competitive cost-wise comparing to many available on market
                            solutions, and we aim to spread customer
                            satisfaction over much longer period than just after purchase.
                        </Typography>
                    </Box>

                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box p={3}>
                        <Typography className={classes.title} variant="h6">Our services</Typography>
                        <ul className={classes.list}>
                            <li>Business advice</li>
                            <li>Web sites</li>
                            <li>Computer systems</li>
                            <li>Startups</li>
                            <li>Internet marketing</li>
                        </ul>

                    </Box>

                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box p={3}>
                        <Typography className={classes.title} variant="h6">Polish Link Limited</Typography>
                        <ul className={classes.list}>
                            <li>Registered in England and Wales</li>
                            <li>Registration number: 07586822</li>
                        </ul>
                        <Box p={3}>
                            <Divider className={classes.divider}/>
                        </Box>
                        <ul>
                            <li>Registered address:</li>
                            <li>1 Lugger Close</li>
                            <li>Chickerell</li>
                            <li>Waymouth</li>
                            <li>DT3 4PJ</li>

                        </ul>
                    </Box>

                   
                </Grid>
            </Grid>
            <Copyright/>
        </Grid>
    )
}

export default Footer
