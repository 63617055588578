import React from 'react';
import Typography from '@material-ui/core/Typography';


export default function Copyright() {
    return (
        <Typography variant="body2" color="primary" align="center">
            {'Copyright © '}
            Polish Link Limited
            {" "}
            {new Date().getFullYear()}
        </Typography>
    );
}
