import React from 'react'
import LinkAppBar from './LinkAppBar'
import Footer from './Footer'
import Container from "@material-ui/core/Container";

function Layout({children}) {

    return (
        <Container disableGutters maxWidth="lg" >
            <LinkAppBar/>
            {children}
            <Footer/>
        </Container>
    )
}
export default Layout